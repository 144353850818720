<template>
    <div class="mainWrapper pb-0 welcomeCstm">
        <section class="blockElement space bg-white">
            <div class="container max-970">
                <div class="row justify-content-center">
                    <div class="col-12 text-center">
                        <h1 class="bold mb-md-3">{{$t('welcomeA.welcomeText1')}}</h1>
                        <p>{{$t('welcomeA.welcomeText2')}}</p>
                        <div class="dualButton text-center my-5">
                            <a :href="static_vars.domainURL+'register/'" class="button fillBtn zulu_btn">{{$t('welcomeA.welcomeText3')}}</a>
                        </div>
                        <div class="video-container mb-5 position-relative">
                            <video class="zulu-video" autoplay="autoplay" loop="loop" muted="true" controls="controls">
                                <source src="/assets/video/zulutrade.mp4" type="video/mp4" />
                                <source src="/assets/video/zulutrade.ogg" type="video/ogg" />
                            </video>
                            <!-- <div id="video-audio"></div> -->
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement space bg-secondary">
            <div class="container max-970">
                <div class="row tradeManually fiveColumn justify-content-center">
                    <div class="col-12 mb-4 mb-md-5 text-center">
                        <h2>{{$t('welcomeA.welcomeText4')}}</h2>
                    </div>
                    <div class="col-12 col-md-4 mb-2">
                        <div class="d-block text-center">
                            <v-lazy-image src="/assets/images/traders-connected.svg" :alt="$t('welcomeA.welcomeText5')" />
                            <p v-html="$t('welcomeA.welcomeText6')"></p>
                        </div>
                    </div>
                    <div class="col-12 col-md-4 mb-2">
                        <div class="d-block text-center">
                            <v-lazy-image src="/assets/images/followers-copy.svg" :alt="$t('welcomeA.welcomeText7')" />
                            <p v-html="$t('welcomeA.welcomeText8')"> </p>
                        </div>
                    </div>
                    <div class="col-12 col-md-4 mb-2">
                        <div class="d-block text-center">
                            <v-lazy-image src="/assets/images/traders-compensation.svg" :alt="$t('welcomeA.welcomeText9')" />
                            <p v-html="$t('welcomeA.welcomeText10')">  </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement space bg-dark why-join">
            <div class="container">
                <div class="row align-items-center w-75 mx-auto">
                    <div class="col-12 mb-4 mb-md-5 text-center">
                        <h2 class="text-white">{{$t('welcomeA.welcomeText11')}}</h2>
                    </div>

                    <div class="col-12 col-md-6 d-flex mb-2">
                        <span class="icon-custom-diagonal icon-discover-diagonal me-1"></span>
                        <div class="text-white w-90">
                            <p class="text-white">{{$t('welcomeA.welcomeText12')}}</p>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 d-flex mb-2">
                        <span class="icon-custom-diagonal icon-participate-diagonal me-1"></span>
                        <div class="text-white w-90">
                            <p class="text-white">{{$t('welcomeA.welcomeText13')}}</p>
                        </div>
                    </div>
                    <div class="col-16 col-md-6 d-flex mb-2">
                        <span class="icon-custom-diagonal icon-experience-diagonal me-1"></span>

                        <div class="text-white w-90">
                            <p class="text-white">{{$t('welcomeA.welcomeText14')}}</p>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 d-flex mb-2">
                        <span class="icon-custom-diagonal icon-learn-diagonal me-1"></span>

                        <div class="text-white w-90">
                            <p class="text-white">{{$t('welcomeA.welcomeText15')}}</p>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 d-flex mb-2">
                        <span class="icon-custom-diagonal icon-support-diagonal me-1"></span>

                        <div class="text-white w-90">
                            <p class="text-white" v-html="$t('welcomeA.welcomeText16')"></p>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 d-flex mb-2">
                        <span class="icon-custom-diagonal icon-global-diagonal me-1"></span>
                        <div class="text-white w-90">
                            <p class="text-white" v-html="$t('welcomeA.welcomeText17')"></p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement space welcome-a-page">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h2 class="text-center">{{$t('welcomeA.welcomeText18')}}</h2>
                        <p class="text-center mb-4">{{$t('welcomeA.welcomeText19')}}</p>
                        <div class="signup-bg">
                            <!-- <v-lazy-image class="fxcmImage" src="/images/bg-left.png" alt="Welcome to ZuluTrade | ZuluTrade Social Trading 10">
                                <v-lazy-image class="fxcmImage" src="/images/bg-left.png" alt="Welcome to ZuluTrade | ZuluTrade Social Trading 10"> -->
                            <!-- <div class="bg-white boxed px-md-5 py-md-4 mx-auto mb-3" style="max-width: 400px; width: 100%;">
                                <h2 class="text-center bold mb-md-4">Join us at ZuluTrade</h2>
                                <p>Create a real money account Or practise with a Demo</p>
                                <form class="inputForm">
                                    <h3 class="text-center mb-md-2 mb-2">Create an account</h3>
                                    <a href="javascript:void(0)" class="borderButton d-flex align-items-center mb-2">
                                        <v-lazy-image src="/assets/images/google-login.svg" loading="lazy" width="24" height="24" alt="google" title="Google Login" />
                                        <span>Continue with Google</span>
                                    </a>
                                    <a href="javascript:void(0)" class="borderButton d-flex align-items-center mb-2">
                                        <v-lazy-image src="/assets/images/mac-login.svg" loading="lazy" width="24" height="24" alt="Apple" title="Continue with Apple" />
                                        <span>Continue with Apple</span>
                                    </a>
                                    <a href="javascript:void(0)" class="borderButton d-flex align-items-center mb-2">
                                        <v-lazy-image src="/assets/images/finvasia-login.svg" loading="lazy" width="24" height="24" alt="Finvasia" title="Continue with Finvasia ID" />
                                        <span>Continue with Finvasia ID</span>
                                    </a>
                                    <div class="text-center my-4">
                                        <p class="or medium text-center">OR</p>
                                    </div>
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="Username or email" />
                                    </div>
                                    <div class="form-group mb-4">
                                        <div class="position-relative iconInput mb-3">
                                            <input type="password" class="form-control" placeholder="Enter Password" />
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                class="feather feather-eye-off position-absolute eye pointer"
                                                size="23"
                                            >
                                                <path
                                                    d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"
                                                ></path>
                                                <line x1="1" y1="1" x2="23" y2="23"></line>
                                            </svg>
                                        </div>

                                        <div class="strenghtPassword">
                                            <h6>Password must have:</h6>
                                            <ul class="passwordStrenth">
                                                <li>1 uppercase character</li>
                                                <li>8 or more characters</li>
                                                <li class="red">at least 1 number</li>
                                                <li class="green">at least 1 letter</li>
                                                <li>at least 1 special character</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="buttonElement mb-3">
                                        <router-link to="/register" class="button fillBtn zulu_btn gradient large">Create account</router-link>
                                    </div>
                                </form>
                            </div> -->
                            <Register />
                        </div>
                        <p class="f-12 text-center" v-html="$t('welcomeA.welcomeText20')"> </p>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
    import Register from '@/views/auth/register.vue'
    export default {
        data() {
            return {}
        },
        components : {
            Register
        }
    }
</script>
